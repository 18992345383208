/* eslint-disable */ 
import React, { FC } from "react";
import classnames from "classnames/bind";

import * as styles from "./BoxOnImage.module.scss";

const cx = classnames.bind(styles);

const placementArgs = [
  "tl",
  "tr",
  "bl",
  "br",
  "tc",
  "rc",
  "bc",
  "lc",
  "c"
] as const;
export type Placement = (typeof placementArgs)[number];

type RootProps = React.PropsWithChildren<{}>;
/**
 * Container class wraps the rest of the elements and as position: relative;
 *
 * @example
 * import * as BoxOnImage from "./path/../"
 *
 * <BoxOnImage.Root>
 *   <BoxOnImage.ImageBackgrond src={"image/source.png"} alt="image alt text in needed">
 *     <BoxOnImage.Posiotion position="tl">
 *        {children}
 *     </BoxOnImage.Posiotion>
 *   </BoxOnImage.ImageBackgrond>
 * </BoxOnImage.Root>
 */
export const Root: React.FC<RootProps> = (props) => {
  const { children } = props;

  return <div className={cx("container")}>{children}</div>;
};

type ImageProps = {
  src: string;
  alt: string;
};
/**
 * Image set to full width and height
 */
export const ImageBackground: React.FC<ImageProps> = (props) => {
  const { src, alt } = props;

  return <img src={src} alt={alt} className={cx("image")} />;
};

type PositionProps = React.PropsWithChildren<{
  /** Abbreviations for top-left, bottom-right and so on */
  position: Placement;
}>;
/**
 * Helper component to keep the positioning separate from the text-boxes
 */
export const Position: FC<PositionProps> = (props) => {
  const { position, children } = props;

  return <div className={cx("position", position)}>{children}</div>;
};
