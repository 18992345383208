import React from "react";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeIconWrapper from "../FontAwesomeIconWrapper/FontAwesomeIconWrapper";
import CardLoading from "../Cards/Loading/Loading";

import type { ListSearchResultContainerProps } from "../../../site/parts/listSearch/type.d";

export default function ListSearchResultContainer(props: Readonly<ListSearchResultContainerProps>) {
  const {
    SelectedCardComponent,
    isLoading,
    list,
    localizedForListSearch,
    paginationSize
  } = props;

  let prevGroupByValue = "";

  if (isLoading) {
    return (
      Array.from({ length: paginationSize }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CardLoading key={index} />))
    );
  }

  return (
    (list?.length > 0) ? (
      <div className="mt-lg">
        { list.map((item, index) => {
          if (item.groupBy?.value) {
            const showGroupBy = item.groupBy.value !== prevGroupByValue;
            prevGroupByValue = item.groupBy.value;
            return (
              <>
                {showGroupBy
                  && (
                    <div className="mt-xl">
                      <div className="flex flex-row items-center">
                        { item.groupBy?.isDate && <FontAwesomeIconWrapper className="mr-xs text-icon-action" icon={faCalendarDays} /> }
                        <p className="text-xxl font-bold">{item.groupBy.value}</p>
                      </div>
                      <div className="w-full h-px bg-surface-action-primary-disabled mb-md" />
                    </div>
                  )}
                <SelectedCardComponent index={index} {...item} localizedForListSearch={localizedForListSearch} />
              </>
            );
          }
          return (
            <SelectedCardComponent index={index} {...item} localizedForListSearch={localizedForListSearch} />
          );
        })}
      </div>
    )
      : (<p className="mt-lg">{localizedForListSearch.noResults}</p>)
  );
}
