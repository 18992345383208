/* eslint-disable */
import React, { FC } from "react";

type Props = {
  text: string | undefined,
  as?: keyof JSX.IntrinsicElements,
  [x: string]: any
}

// TODO: dangerouslySetInnerHTML is needed to render properly but we can sanitize with a 3rd party tool
// https://github.com/enonic/lib-react4xp/issues/33
// See also: https://discuss.enonic.com/t/portallib-sanitizehtml-removes-macros-added-in-htmlarea/2712
const Richtext: FC<Props> = (props) => {
  const {
    as = "div", text = "", className = "", ...rest
  } = props;
  return React.createElement(as, {
    dangerouslySetInnerHTML: { __html: text },
    className,
    ...rest
  });
};

export default Richtext;
