import React, { useState, useEffect } from "react";
import { forceArray } from "/lib/util/objects";
import "./SideMenu.scss";

type SideMenuElement = {
  title: string | undefined;
  link: string | undefined;
};

type SideMenuProp = {
  data: SideMenuElement[] | SideMenuElement;
};

function SideMenu(props: SideMenuProp) {
  const { data } = props;
  const [isSelected, setIsSelected] = useState<string | undefined>();

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll<HTMLHeadingElement>("h2[id]");
      let selectedId: string | null = null;

      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();

        // Check if the element is in the desired range of the viewport (e.g., between 0 and 200px from the top)
        if (rect.top <= 200 && rect.top >= 0) {
          if (!selectedId || rect.top < document.getElementById(selectedId)!.getBoundingClientRect().top) {
            selectedId = element.id;
          }
        }
      });

      if (selectedId) {
        setIsSelected(`#${selectedId}`);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Only runs once on mount and unmount

  const anchorClasses = [
    "no-underline",
    "shadow-none",
    "py-md",
    "pl-sm",
    "nt-border-x-0",
    "hover:text-neutral",
    "hover:bg-surface-action-menu-hover",
    "nt-focus",
    "focus:bg-surface-action-menu-hover",
    "focus:rounded-sm"
  ].join(" ");

  const handleClick = (key: string) => {
    setIsSelected(key);
    const element = document.querySelector(key);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav className="border border-solid border-neutral-subtle rounded-sm">
      <div className="divide-y-sm divide-neutral divide-solid flex flex-col">
        {forceArray(data).map((element: SideMenuElement) => {
          const key = element.link;

          return (
            <a
              key={key}
              className={`${anchorClasses} ${isSelected === key ? "selected" : ""}`}
              href={element.link}
              onClick={(e) => {
                e.preventDefault();
                handleClick(key);
              }}
              data-skip-to-content="true" // Changes focus to new content
            >
              {element.title}
            </a>
          );
        })}
      </div>
    </nav>
  );
}

export default function(props: SideMenuProp) {
  return <SideMenu {...props} />;
}
