import React, { useEffect } from "react";
import { Checkbox, CheckboxGroup } from "@hdir/ui";

import type { FilterOptionsProps } from "../../../site/parts/listSearch/type.d";

import "./FilterOptions.scss";

export default function FilterOptions(props: Readonly<FilterOptionsProps>) {
  const {
    filterLists,
    currentFilter,
    handleCheckboxChange,
    setCountCheckedHelper,
    isCloseable,
    onFetchWithFilter
  } = props;

  // Filter when filter value is selected
  if (!isCloseable) {
    useEffect(() => {
      onFetchWithFilter(false);
    }, [currentFilter]);
  }

  return (
    <div className="flex nt-flex-col tablet:flex-row">
      {filterLists?.length > 0 && filterLists.map((list, listIndex) => (
        list?.filterList.length > 0 && (
          <div className={isCloseable ? "w-full tablet:w-1/3" : "w-full mt-lg nonCloseable"} key={list.id}>
            <CheckboxGroup
              key={list.id}
              name={list.label}
              label={<h3 className="text-lg font-bold">{list.label}</h3>}
              value={currentFilter[listIndex]?.values}
              onChange={(value) => {
                handleCheckboxChange(value, list.field);
              }}
            >
              {list?.filterList.map((filterItem) => (
                <Checkbox
                  key={filterItem.id}
                  label={filterItem.displayName ?? filterItem.value}
                  name={filterItem.value}
                  value={filterItem.value}
                  onChange={(e) => { setCountCheckedHelper(e); }}
                  aria-description={`Filtrer søketreff på ${filterItem.value}`}
                />
              ))}
            </CheckboxGroup>
          </div>
        )))}
    </div>
  );
}
