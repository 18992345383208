/* eslint-disable */
import React from "react";

type Props = {
  rotate?: number
}

function ChevronIcon({ rotate = 0 }: Readonly<Props>) {
  return (
    <svg style={{ transform: `rotate(${rotate}deg)` }} width="25px" height="25px" viewBox="0 0 29 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <g id="" transform="translate(-1272.000000, -494.000000)" stroke="currentColor" strokeWidth="2">
          <polyline id="" transform="translate(1286.435029, 509.435029) rotate(-135.000000) translate(-1286.435029, -509.435029) " points="1295.93503 499.935029 1295.93503 518.935029 1276.93503 518.935029" />
        </g>
      </g>
    </svg>
  );
}

export default ChevronIcon;
