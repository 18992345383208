import React from "react";
import { TextField, Button } from "@hdir/ui";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeIconWrapper from "../FontAwesomeIconWrapper/FontAwesomeIconWrapper";

import type { ListSearchSearchComponentProps } from "../../../site/parts/listSearch/type.d";

import "./ListSearchSearchComponent.scss";

export default function ListSearchSearchComponent(props: Readonly<ListSearchSearchComponentProps>) {
  const {
    fetchListOnSearchInput, setSearchQuery, searchInputRef, localizedForListSearch, title
  } = props;

  const fetchWithSearchHelper = (event: React.FormEvent, searchRef: React.RefObject<HTMLInputElement>) => {
    event.preventDefault();
    if (searchRef.current) {
      const inputValue = searchRef.current.value;
      fetchListOnSearchInput(inputValue);
      setSearchQuery(inputValue);
      searchRef.current.blur();
    }
  };

  return (
    <div className="searchContainer pt-lg pb-xl tablet:pb-xxl px-md tablet:px-xxl rounded-md bg-surface-feedback-success">
      <form className="flex gap-md" onSubmit={(e) => fetchWithSearchHelper(e, searchInputRef)}>
        <FontAwesomeIconWrapper className="absolute mt-[44px] ml-[13px] text-color-action-default" size="lg" icon={faSearch} />
        <TextField
          label={<h2 className="text-lg">{title || (localizedForListSearch?.search ?? "Søk")}</h2>}
          fullWidth
          className="searchInput flex-1 !gap-sm"
          name="search"
          type="text"
          ref={searchInputRef}
        />
        <Button className="self-end" variant="primary" type="submit">{localizedForListSearch?.search ?? "Søk"}</Button>
      </form>
    </div>
  );
}
