import React from "react";

import { Link } from "@hdir/ui";
import FontAwesomeIconWrapper from "/react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper";
import { faArrowDownToLine, faArrowRight, faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";

type ProcessedLinkProps = {
  link: {
    linkText: string | undefined;
    linkUrl: string | undefined;
    linkType: string | null;
  }
}

function LinkCardWithIcon(props: Readonly<ProcessedLinkProps>) {
  const {
    link: {
      linkText,
      linkUrl,
      linkType
    }
  } = props;

  let icon = faArrowRight;

  // eslint-disable-next-line default-case
  switch (linkType) {
    case "pdf":
      icon = faArrowDownToLine;
      break;
    case "external":
      icon = faArrowUpRightFromSquare;
      break;
    case "internal":
      break;
  }

  return (
    <Link className="shadow-none !w-4/5 !flex-row justify-between !pr-md" variant="card" href={linkUrl}>
      <p>{linkText}</p>
      <FontAwesomeIconWrapper icon={icon} className="self-center" style={{ color: "var(--design-color-icon-action-default)" }} />
    </Link>
  );
}

export default function(link: ProcessedLinkProps) {
  return <LinkCardWithIcon {...link} />;
}
