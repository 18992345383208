import React from "react";
import { Link } from "@hdir/ui";

import type { NormerendeProdukterProps } from "../../../../services/getSearchResultsListSearch/type.d";

export default function NormerendeProdukter(props: Readonly<NormerendeProdukterProps>) {
  const {
    contentType,
    korttittel,
    title,
    link,
    showPdfIcon,
    sisteFagligeEndring,
    localizedForListSearch,
    cardRef
  } = props;

  const hasEnoughContent = !!(korttittel || title || link);

  if (hasEnoughContent) {
    return (
      <Link ref={cardRef} href={link} variant="card" className="min-w-full my-md shadow-none">
        <div className="flex flex-col flex-wrap w-full gap-xs p-xs min-w-64 break-words whitespace-normal">
          { (korttittel || title) && <h2 className="font-semibold text-xl">{korttittel || title}</h2> }
          { contentType && <p className="text-neutral-subtle">{contentType}</p> }
          <div className="flex flex-row justify-between gap-xxl">
            { sisteFagligeEndring
              && (
                <time dateTime={sisteFagligeEndring} className="">
                  <div className="flex nt-flex-col tablet:flex-row">
                    <span className="mr-xxs">{localizedForListSearch?.sisteFagligeEndring ?? "Siste faglige endring:"}</span>
                    <span>{sisteFagligeEndring}</span>
                  </div>
                </time>
              )}
            { showPdfIcon
              && <div className="ml-auto rounded-lg border-solid border border-action-secondary text-action-secondary py-xxs px-md mt-0 tablet:mt-xs h-[38px]">PDF</div>}
          </div>
        </div>
      </Link>
    );
  }
}
