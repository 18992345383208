import React from "react";
import { Link, Chip, CardBanner } from "@hdir/ui";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeIconWrapper from "../../FontAwesomeIconWrapper/FontAwesomeIconWrapper";

import type { TilskuddProps } from "../../../../services/getSearchResultsListSearch/type.d";

export default function Tilskudd(props: Readonly<TilskuddProps>) {
  const {
    title,
    korttittel,
    link,
    deadlineDay,
    deadlineMonth,
    themeTilskudd,
    eligibleAppliersTilskudd,
    deadlineExpired,
    deadlineRawFormat,
    localizedForListSearch
  } = props;

  // Display the card if enough content is present
  const hasEnoughContent = !!(korttittel || title || link);

  if (hasEnoughContent) {
    return (
      <Link href={link} variant="card" className={`min-w-full ${deadlineExpired ? "!border-feedback-error" : ""} shadow-none my-md p-0`}>
        { deadlineExpired
          ? (
            <div className="w-full">
              <CardBanner position="top">
                <div className="w-full bg-surface-feedback-error text-feedback-error pl-lg tablet:pl-[144px] py-sm">
                  <p className="flex items-baseline gap-xs font-bold text-lg">
                    <FontAwesomeIconWrapper icon={faTriangleExclamation} />
                    {localizedForListSearch?.expired ?? "Utløpt"}
                  </p>
                </div>
              </CardBanner>
            </div>
          )
          : null }

        <div className="flex nt-flex-col tablet:flex-row p-xs tablet:p-lg desktop:gap-md">
          <time dateTime={deadlineRawFormat} className="flex nt-flex-row tablet:flex-col justify-start tablet:justify-center content-center items-baseline tablet:items-center min-w-24 text-action text-center pr-md">
            <span className="nt-text-md tablet:text-[36px] nt-font-normal tablet:font-bold leading-8 mr-xxs">{deadlineDay}</span>
            <span className="text-md">{deadlineMonth}</span>
          </time>
          <div className="flex flex-col flex-wrap gap-md min-w-64 break-words whitespace-normal">
            <h2 className="text-xl">{title || korttittel}</h2>
            { themeTilskudd?.length > 0 && (
              <div className="flex flex-wrap gap-sm tablet:gap-md">
                {themeTilskudd?.map((theme) => (
                  <Chip key={theme} className="p-xs bg-surface-feedback-success text-icon-feedback-success border-feedback-success leading-4" size="large">
                    {theme}
                  </Chip>
                ))}
              </div>
            )}

            { eligibleAppliersTilskudd?.length > 0 && (
              <p>
                {localizedForListSearch?.eligableAppliers ?? "Kan søkes av"}
                :
                {" "}
                <span className="font-bold">{eligibleAppliersTilskudd?.join(", ")}</span>
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  }
}
