import React from "react";
import { Button } from "@hdir/ui";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeIconWrapper from "../FontAwesomeIconWrapper/FontAwesomeIconWrapper";
import FilterOptions from "../FilterOptions/FilterOptions";

import type { FilterProps } from "../../../site/parts/listSearch/type.d";

export default function Filter(props: Readonly<FilterProps>) {
  const {
    currentFilter,
    filterCloseable,
    filterLists,
    handleCheckboxChange,
    isEditMode,
    isFilterOpen,
    localizedForListSearch,
    onFetchWithFilter,
    onResetFilter,
    setCountChecked,
    setIsFilterOpen
  } = props;

  // Check for empty filterlists
  const hasNonEmptyFilterList = filterLists?.some((list) => list?.filterList.length === 0);

  const toggleFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const setCountCheckedHelper = (event) => {
    if (event.target?.checked) {
      setCountChecked((prevCount) => prevCount + 1);
    } else {
      setCountChecked((prevCount) => prevCount - 1);
    }
  };

  if (isEditMode && hasNonEmptyFilterList) {
    return (
      <div className="bg-red-300 border-2 border-rose-600">
        Feil i et eller flere filter. Sjekk at filteret er satt opp med riktig datafelt for filtering. Vær obs på stavemåte og mellomrom. Datafeltet må være tilgjenglig i utvalget av innhold som er satt (sti, innholdstype, tema osv.).
      </div>
    );
  }

  return (
    <div>
      { !filterCloseable ? (
        <FilterOptions
          filterLists={filterLists}
          currentFilter={currentFilter}
          onFetchWithFilter={onFetchWithFilter}
          handleCheckboxChange={handleCheckboxChange}
          setCountCheckedHelper={setCountCheckedHelper}
          isCloseable={false}
        />
      ) : (
        <div>
          { isFilterOpen && (
            <div className="p-lg tablet:p-xxl mt-md tablet:mt-lg gap-md rounded-sm border-solid border border-neutral">
              <Button variant="tertiary" onClick={toggleFilterOpen} className="float-right w-16 -mt-md -mr-lg">
                <FontAwesomeIconWrapper icon={faXmark} size="xl" />
              </Button>

              <FilterOptions
                filterLists={filterLists}
                currentFilter={currentFilter}
                handleCheckboxChange={handleCheckboxChange}
                setCountCheckedHelper={setCountCheckedHelper}
                isCloseable
              />

              <div className="flex flex-row justify-end mt-md items-end gap-md">
                <Button
                  variant="tertiary"
                  onClick={() => {
                    onResetFilter();
                    setCountChecked(0);
                  }}
                >
                  {localizedForListSearch?.empty ?? "Nullstill"}
                </Button>

                <Button onClick={() => onFetchWithFilter(false)}>
                  {localizedForListSearch?.showResults ?? "Vis resultater"}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
