import React from "react";
import { Link } from "@hdir/ui";

import type { BaseProps } from "../../../../services/getSearchResultsListSearch/type.d";

export default function General(props: Readonly<BaseProps>) {
  const {
    korttittel,
    title,
    preface,
    link
  } = props;

  // Display the card if enough content is present
  const hasEnoughContent = !!(korttittel || title || link);

  if (hasEnoughContent) {
    return (
      <Link href={link} variant="card" className="min-w-full my-lg shadow-none">
        <div className="flex flex-col tablet:flex-row  p-lg">
          <div className="flex flex-col flex-wrap gap-sm min-w-64 break-words whitespace-normal">
            {(korttittel || title) && <h2>{korttittel || title}</h2>}
            { preface && <p>{preface}</p> }
          </div>
        </div>
      </Link>
    );
  }
}
