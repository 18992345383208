/** NOTE
 * This component contains the metadata (contributors, time of first publishment and last change,
 * and previous versions) included in the ContentFooter component.
 * The metadata is separated into its own component as the content type "Nasjonal e-helsestandard"
 * temporarily only uses this component as its footer.
*/

import React from "react";
import { Link } from "@hdir/ui";

import "../ContentFooter/ContentFooter.scss";
import { ContentFooterMetadataPropsType } from "./types.d";

function ContentFooterMetadata(props: Readonly<ContentFooterMetadataPropsType>) {
  const {
    data: {
      contributorRole,
      contributors,
      firstPublish,
      lastChange,
      versionPageUrl,
      phrases,
      usedInContentFooter
    }
  } = props;

  return (
    <div className={usedInContentFooter ? "" : "flex flex-col px-md tablet:px-0"}>
      <div className={usedInContentFooter ? "" : "contentFooter mt-xxl mb-xxl self-center w-full"}>
        {contributors && (
          <div className="nt-block tablet:flex items-center">
            <div className="flex flex-wrap">
              {contributors.map((contributor: ContributorAndLogo) => (
                <img className="pr-sm max-h-[50px]" key={contributor.name} src={contributor.logoUrl} alt={contributor.name} />
              ))}
            </div>
            <p className="pl-0 tablet:pl-sm pt-sm tablet:pt-0 text-sm tablet:contentFooter-border-left">{contributorRole}</p>
          </div>
        )}
        <div className="nt-block tablet:flex mt-md">
          {firstPublish && (
            <p className="pl-0 pr-sm text-neutral-subtle">
              {phrases["contentFooter.firstPublished"]}
              {" "}
              {firstPublish}
            </p>
          )}
          {lastChange && (
            <p className="pr-sm pl-0 tablet:pl-sm text-neutral-subtle tablet:contentFooter-border-left">
              {phrases["contentFooter.lastChange"]}
              {" "}
              {lastChange}
            </p>
          )}
          <Link href={versionPageUrl} className="pl-0 tablet:pl-sm shadow-none !rounded-none tablet:contentFooter-border-left ">
            {phrases["string.versionHistory"]}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default function(props: Readonly<ContentFooterMetadataPropsType>) {
  return <ContentFooterMetadata {...props} />;
}
