// extracted by mini-css-extract-plugin
var _1 = "Jw03GtFZVwgFfiHhOWK8";
var _2 = "JmegUs2JSEruWv1uwC8_";
var _3 = "srM4xduR22ikEFTTV5Q_";
var _4 = "fPKjXsvj6CHPCws802h4";
var _5 = "Zw3lJcEhSFMgc7rPW24K";
var _6 = "PvaoiTe54kCSQA3SGCN2";
var _7 = "CMPrZWlE3bDm4dGXTH6O";
var _8 = "VfNflqya0cNCaYKNrfb7";
var _9 = "NDy6vBO6njge7WL83rU5";
var _a = "amDGMywL0N3UcCcZrQd9";
var _b = "sotfPBoKAG0En7kZzffB";
var _c = "e_8y7E31LhN3FWsvPVkq";
var _d = "MJWxlTMPStYNWtGBWBlS";
var _e = "gclpfdunaZdRK2z7z4IU";
var _f = "w_gxxSQ4qTkgnMgIv12Y";
var _10 = "BHp1WuflBC8VFU3wiTzb";
export { _1 as "bl", _2 as "blue-dark", _3 as "blue-light", _4 as "br", _5 as "half", _6 as "heading", _7 as "icon", _8 as "large", _9 as "medium", _a as "noPadding", _b as "pink", _c as "purple", _d as "small", _e as "text-box", _f as "tl", _10 as "tr" }
