/* eslint-disable */ 
import React, { FC } from "react";
import classnames from "classnames/bind";

import * as styles from "./ThemeBox.module.scss";

const cx = classnames.bind(styles);

export const cornerRounding = [
  "tl",
  "tr",
  "bl",
  "br"
] as const;
export type Placement = typeof cornerRounding[number];

export type ThemeBoxProps = {
  theme?: "purple" | "pink" | "blue-light" | "blue-dark";
  roundedBorders?: Array<Placement> | Placement;
  noPadding?: boolean,
  children: React.ReactNode | string
  size?: "small" | "medium" | "large" | "half"
  className?: string
  heading?: string
} & React.HTMLAttributes<HTMLDivElement>;
/**
 * Flexible text-box with diffent color themes and border-rounding
 */
const ThemeBox: FC<ThemeBoxProps> = (props) => {
  const {
    theme = "blue-dark",
    roundedBorders,
    noPadding = false,
    children,
    size = "small",
    className = "",
    heading = null,

    ...rest
  } = props;

  const styles = cx("text-box", theme, roundedBorders, size, { noPadding }, className);

  return (
    <div {...rest} className={styles}>{children}</div>
  );
};

export default ThemeBox;
