import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Loading() {
  return (
    <div className="card rounded-sm mt-lg">
      <Skeleton height={30} />
      <div className="card-body">
        <h5 className="card-title"><Skeleton width={150} /></h5>
        <p className="card-text"><Skeleton count={3} /></p>
      </div>
    </div>
  );
}
