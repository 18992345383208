/* eslint-disable */
import React from "react";
import classnames from "classnames/bind";

import ThemeBox from "../ThemeBox";
import type { ThemeBoxProps } from "../ThemeBox";
import * as styles from "../ThemeBox.module.scss";

const cx = classnames.bind(styles);

export type ThemeBoxWithIconProps = {
  heading: string,
  iconSrc?: string | null
} & ThemeBoxProps

const ThemeBoxWithIcon: React.FC<ThemeBoxWithIconProps> = (props) => (
  <ThemeBox {...props}>
    {
      props.heading
      && (
        <span className={cx("heading")}>
          {props.iconSrc
            && <img alt="" className={cx("icon", props.theme)} src={props.iconSrc} />}
          {props.heading}
        </span>
      )
    }
    {props.children}
  </ThemeBox>
);

export default ThemeBoxWithIcon;
